.statsContainer{
    // background: #FFFFFF;
    padding: 4px 13px 14px 13px;
}

.statsCardArrowIcon{
    width: 4.5px;
    margin-left: 6px;
    display: initial !important;
}

.statsPointContainer{
    display: flex;
    justify-content: space-between;
    // background: #2C245C;
    padding: 15px 15px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.statsHeader{
    color: #FFFFFF;
    font-size: 1.8rem;
}

.stattsPoints{
    color: #FFFFFF;
    font-size: 1.2rem;
    text-align: center;
    background: #403875;
    padding: 2px 8px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 9rem;
    align-items: center;
}

.statsCardContainer{
    padding-top: 2px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 9px;
}

.statsCard{
    height: 16.3rem;
    box-shadow: 0px 4px 4px RGB(0 0 0 / 25%);
    // background: #2C245C;
    border-radius: 8px;
    padding: 10px 10px 8px 10px;
    color: white;
    position: relative;
    border: 1px solid #FDCA00;
}

.statsCardText{
    display: flex;
    justify-content: space-between;
}