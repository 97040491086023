.leaderBoardTop{
    position: relative;
    height: 23rem;
    margin: auto;
    width: 92%;

    &__firstRank{
        position: absolute;
        bottom: 0%;
        left: 34%;
        width: 20%;
    }
    &__secondRank{
        position: absolute;
        bottom: 1%;
        left: 7%;
        width: 19%;
    }
    &__thirdRank{
        position: absolute;
        bottom: 0%;
        left: 76%;
        width: 15%;
    }

    &__infoIcon{
        position: absolute;
        bottom: 94%;
        left: 0%;
    }

    &__infoText{
        position: absolute;
        bottom: 76%;
        color: #FFFFFF;
        left: -2%;
        font-size: 1.4rem;
        width: 226px;
        padding: 9px 11px 11px 35px;
        background: #EC2F1A;
        opacity: 0.8;
    }

&__red{
    position: absolute;
    bottom: -6px;
    left: -1.4%;
    width: 32.4%;
}
&__redGradient{
    position: absolute;
    bottom: -6px;
    left: -1%;
    width: 22.7%;
}
&__red2{
    position: absolute;
    bottom: 0px;
    left: 25%;
    width: 5.9%;
}
&__orange{
    position: absolute;
    bottom: -3%;
    left: 66.4%;
    width: 36%;
}
&__orangeGradient{
    position: absolute;
    bottom: 0%;
    left: 66.4%;
    width: 7%;
}
&__yellow{
    position: absolute;
    bottom: -3%;
    left: 24%;
    width: 48.6%;
}
&__yellowGradient{
    position: absolute;
    bottom: 1%;
    left: 25%;
    width: 23.3%;
}
&__rank1{
    width: 27%;
    position: absolute;
    left: 35%;
    bottom: 65%;
    border-radius: 50%;
}
&__rank2{
    width: 27%;
    position: absolute;
    bottom: 40%;
    left: -1%;
    border-radius: 50%;
}
&__rank3{
    position: absolute;
    width: 27%;
    left: 74%;
    bottom: 26%;
    border-radius: 50%;
}
&__institue1{
    position: absolute;
    font-style: normal;
    font-weight: bold;
    color: #FFFFFFDE;
    left: 35%;
    font-size: 2rem;
    bottom: 39%;
    width: 30%;
    word-break: break-word;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &-point{
        position: absolute;
        left: 40%;
        color: #FFFFFFDE;
        bottom: 16%;
        font-size: 1.8rem;
        &-text{
            // position: absolute;
            // left: 50%;
            color: #FFFFFFDE;
            padding-left: 2px;
            // bottom: 17%;
            font-size: 1.3rem;
        
        }
    }
}
&__institue2{
    position: absolute;
    font-style: normal;
    font-weight: bold;
    color: #FFFFFFDE;
    left: 3%;
    bottom: 23%;
    font-size: 1.3rem;
    width: 24%;
    word-break: break-word;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &-point{
        position: absolute;
        color: #FFFFFFDE;
        left: 9%;
        bottom: 6%;
        font-size: 1.3rem;
        &-text{
            position: absolute;
            color: #FFFFFFDE;
            left: 16%;
            bottom: 6%;
            font-size: 1rem;
        }
    }
}
&__institue3{
    position: absolute;
    font-style: normal;
    font-weight: bold;
    color: #FFFFFFDE;
    bottom: 12%;
    left:74%;
    width: 24%;
    font-size: 1.2rem;
    word-break: break-word;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &-point{
        position: absolute;
        color: #FFFFFFDE;
        bottom: 5%;
        left: 80%;
        font-size: 1rem;
        &-text{
            position: absolute;
            color: #FFFFFFDE;
            bottom: 5.8%;
            left: 85%;
            font-size: .8rem;
        }
    }
}
}