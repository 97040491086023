.Tasks {
  padding: 24px 24px 80px;

  &__Top {
    margin-bottom: 24px;

    &--Heading {
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 150%;
      /* identical to box height, or 33px */

      display: flex;
      align-items: center;

      color: white;
    }

    &--SubHeading {
      margin-top: 8px;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 130%;

      color: white;
    }
  }

  &__Body {}
}

.TaskCard {
  padding: 16px 20px;
  background: linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
backdrop-filter: blur(40px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 16px;
  // background: #FFFFFF;
  // border: 1px solid #9278FF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__Stats {
    // padding: 16px 20px;
    border-bottom: 1px solid #e5e5e5;

    &__Top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 16px;

      &__Label {
        display: flex;
        align-items: center;

        &--Info {
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 150%;
          /* identical to box height, or 24px */

          display: flex;
          align-items: center;

          color: white;
        }

        img {
          margin-right: 12px;

          width: 40px;
          height: 40px;

          border-radius: 6px;
          // border: 1.5px solid #e5e5e5;

          overflow: hidden;
        }
      }

      &__Points {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--Count {
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 130%;
          /* identical to box height, or 16px */

          text-align: right;

          color: white;

          white-space: nowrap;
        }

        img {
          width: 24px;
          height: 24px;

          margin-bottom: 4px;
        }
      }
    }

    &--Completed {
      margin-top: 8px;

      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 130%;
      /* identical to box height, or 16px */

      color: rgba(0, 0, 0, 0.6);
    }

    &--NotCompleted {
      margin-top: 3px;

      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 130%;
      /* identical to box height, or 16px */

      color: white;
    }
  }

  &__Action {
    // padding: 16px 20px;
    margin-top: 16px;

    &--Info {
      font-style: normal;
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;

      color: white;

      // margin-bottom: 16px;
    }

    &--Button {
      width: 84%;
      
      background: #FDCA00;
      border-radius: 8px;

      padding: 8px 0;

      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}