.Layout {
  min-height: 100%;
  background: url("../../Resources/images/carnival/detailsBg.png") no-repeat;
  // background-image: url("../../Resources/images/IPL/drawerTopIcon.png");
  // background-position: top right;
  // background-size: ;
  // background-position: ;

  background-size: contain;
  background-color: #f8ecfa;
  // background-color: #FFE7BE;
  // background-color: #645494;
  // background: radial-gradient(50% 50% at 50% 50%, #F4BB40 0%, #FAE19D 53.12%, #F5BE41 100%);
}

.layoutBottom {
  padding: 24px;
}

.Drawer {
  // border-bottom: 24px solid #ffffff;
  // background: linear-gradient(97.52deg, #2B1451 0%, #49218A 100%);
  // background:
  //   url("../../Resources/images/baisakhi/DrawerImage.svg") no-repeat;
  // background-repeat: no-repeat;
  // background: url("../../Resources/images/IPL/drawerTopIcon.png") no-repeat;
  background-position: 101% 0%;
  // background-size: 87px;

  // background-size: inherit;

  // -webkit-transition: background-color 0.3s linear, border-color 0.3s linear;
  // -ms-transition: background-color 0.3s linear, border-color 0.3s linear;
  // transition: background-color 0.3s linear, border-color 0.3s linear;

  &__Top {
    padding: 24px 24px 32px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__Profile {
      display: flex;
      align-items: center;

      &--Name {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 130%;
        /* identical to box height, or 21px */

        color: white;

        display: flex;
        align-items: center;
        // margin-top: -50px;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(1.3 * 1.6rem);
          width: 50px;
        }
      }

      &--Img {
        display: block;
        margin-right: 16px;

        border: 2px solid #ffffff;

        background-color: #eeeaff;
        box-sizing: border-box;
        min-width: 48px;
        min-height: 48px;
        width: 48px;
        height: 48px;
        // margin-top: -67px;

        border-radius: 50%;
        overflow: hidden;
      }
    }

    &__Points {
      background: #fdca00;
      border-radius: 120px;

      display: flex;

      padding: 10px 12px;

      border-radius: 120px;
      z-index: 1;
      // margin-right: 20px;

      &--Count {
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 130%;
        /* identical to box height, or 16px */

        text-align: right;

        color: black;
        margin-right: 4px;

        display: flex;
        align-items: center;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(1.3 * 1.2rem);
          width: 30px;
        }
      }
    }
  }

  &__Body {
    margin-bottom: 0px;
    padding: 0px 24px 14px 24px;

    display: grid;
    // grid-template-columns: 1fr 1fr;

    &__Stats {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      &--Earning {
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 100%;
        /* identical to box height, or 20px */

        display: flex;
        align-items: center;

        color: white;

        display: flex;
        align-items: center;

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(2rem);
          width: 50px;
        }
      }

      &--Goal {
        font-style: normal;
        font-weight: 700;
        font-size: 2.1rem;
        line-height: 130%;
        /* or 18px */

        color: white;

        // margin-top: 8px;

        display: flex;
        align-items: center;

        &--value {
          // color: #FFAD3C;
          margin-left: 3px;
        }

        &--Shimmer {
          border-radius: 40px;
          display: inline-block;
          height: calc(1.3 * 1.4rem);
          width: 50px;

          margin-left: 4px;
        }
      }
    }
  }

  &__Button {
    width: fit-content;
    display: flex;
    align-items: center;

    background-color: white;
    border-radius: 4px;

    padding: 12px;

    &--Text {
      font-style: normal;
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 130%;
      /* or 18px */

      color: rgba(0, 0, 0, 0.87);
    }

    img {
      margin-right: 8px;
    }
  }
}

.navBarContaner {
  background: linear-gradient(110.03deg, #8800f3 7.45%, #a737ff 87.18%);
  padding: 20px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  p-filter: blur(1px);
  position: sticky;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  top: 0px;
  background-color: radial-gradient(
    52.78% 51.5% at 50% 50%,
    #fcc745 0%,
    #fca016 100%
  );
  z-index: 10;
}

.NavBar {
  background: white;
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 22px;
  // background: linear-gradient(90deg, #019FD1 0%, #7AE0EE 108.74%);
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  margin: 0 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: sticky;
  top: 0px;
  display: flex;
  // background-color: #8800F3;

  z-index: 10;
  border-radius: 32px;
  justify-content: space-between;
  padding: 6px 6px 6px 6px;
  border: 1px solid white;

  &::-webkit-scrollbar {
    display: none;
  }

  &__MenuItem {
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    mix-blend-mode: normal;
    padding: 7px 8px;
    white-space: nowrap;
    width: 50%;
  }
}
