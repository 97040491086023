.ProgressBar {
  height: 8px;
  width: 100%;
  background: #F2F2F2;
  border: 1px solid #E5E5E5;
  box-shadow: inset 0px 2px 4px RGB(0 0 0 / 16%);
  background-color: white;

  border: 0.2px solid #e5e5e5;
  border-radius: 120px;

  display: flex;
  align-items: center;

  overflow: hidden;

  span {
    display: block;
    height: 100%;
  }

  &__Indicator {
    border-radius: 4px;
    overflow: hidden;

    &--Fill {
      border-radius: 12px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      -webkit-animation-name: progressBar;
      animation-name: progressBar;
      // background: linear-gradient(147.14deg, #FF8800 6.95%, #E63535 93.05%);
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      animation-delay: 1.5s;
      -webkit-animation-delay: 1.5s;
    }
  }
}

@-webkit-keyframes progressBar {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}

@keyframes progressBar {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}
