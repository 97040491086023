.newRewardInfoHolder {
  margin-top: 10px;
  // background: linear-gradient(0deg, #582363 0%, #902684 100%);
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
backdrop-filter: blur(40px);
/* Note: backdrop-filter has minimal browser support */
 border: 1px solid #FDCA00;
border-radius: 16px;
  // background: #FCA53E;
    padding: 20px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  // border: 1px solid #9278FF;
  border-radius: 8px;
}

.newRewardsInfoIcon {
  display: flex;
  justify-content: center;
}

.newRewardInfoText {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin-top: 6px;
  text-align: center;
}

.newRewards {
  padding: 6px 18px 45px;
  &__img {
    width: 95%;
    border-radius: 8px;
    margin: 25px 0 0 0;
    border: 2px solid #FDCA00;
  }

  &__hr {
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    opacity: 0.54;
    margin: 26px 0 18px;
  }
}

.newRewardsHeader {
  h1 {
    margin: 8px 0;
    font-size: 22px;
    line-height: 130%;
    color: white;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 130%;
    color: white;
  }
}

.newRewardsIconHeader {
  display: flex;
  align-items: flex-start;
  margin: 16px 0 4px 0;

  img {
    margin-top: 5px;
    margin-right: 16px;
  }

  h1 {
    margin: 0;
    font-size: 16px;
    line-height: 150%;
    color: white;
    margin-bottom: 4px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 130%;
    color: white;
  }
}

.bottomDrawerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 500;

  .bottomDrawer {
    border: 1px solid #6A50C9;
    width: 100vw;
    position: fixed;
    bottom: -1000px;
    transition: all 0.5s ease-in-out;
    left: 0;
    background: #263E24;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.12);
    border-radius: 12px 12px 0px 0px;

    &__header {
      border-bottom: 1px solid #6A50C9;
      padding: 26px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 18px;
        line-height: 23px;
        color: #FFFFFF;
      }

      img {
        cursor: pointer;
      }
    }

    &__content {
      padding: 16px 24px 0;
      max-height: 60vh;
      overflow: scroll;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
      }
    }

    &__footer {
      padding: 16px 24px;
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);

      button {
        width: 100%;
        border: none;
        padding: 12px;
        box-shadow: 0px 4px 8px rgba(49, 143, 67, 0.16);
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
    }
  }
}

.shareStudentImageCard {
  background: #69845F;
  // background-color: #1D055F;
  // border: 1px solid #9278FF;
  // background: linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
  
  
  border-radius: 16px; ;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  &__inner {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 142%;
      color: white;
    }
  }
}

.bottomDrawerCard {
  padding: 16px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__img {
    background: rgba(255, 255, 255, 0.87);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &__text {
    width: 75%;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
  }
}