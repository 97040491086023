.Ranking {
  margin-bottom: 24px;
  // background: #54881C;
  background:  linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  // border: 1px solid #9278FF;
  border-radius: 4px;

  &__Headers {
    padding: 16px 24px 17px 6px;
    display: grid;
    grid-template-columns: 1.5fr 2.5fr 1fr;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 14px;
    // border-bottom: 1px solid #9278FF;
  }

  &__Block {
    padding: 16px 25px 16px 8px;
    display: grid;
    grid-template-columns: 1.5fr 2.5fr 1fr;
    column-gap: 8px;
  }

  &--points {
    font-size: 1.6rem;
    text-align: right;
    display: flex;
    align-items: center;
    color: white;
  }

  &--rank {
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }
  &--institute {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    color: white;
  }
}
