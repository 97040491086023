.MilestoneSectionContainer {
  height: 75%;
  // background: #FFFFFF;
}

.MilestoneHeaderText {
  font-size: 1.5rem;
  color: white;
  padding: 19px 15px 15px 15px;
  text-align: center;
  &__amount{
    color: #FCC44D;
    font-size: 1.5rem;
    margin: 0px 4px;

  }
}

.MilestoneSection {
  // background: linear-gradient(rgb(36, 6, 117) 0%, rgb(28, 5, 90) 100%);
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;

  grid-gap: 24px;

  overflow: hidden;
  padding: 10px 20px 0px 20px;

  &__Road {
    position: absolute;
    top: calc(20px + 21.5px);
    left: 20%;
    width: 60%;
    box-sizing: border-box;

    &--Block {
      display: block;
      border: 6px solid #787689;

      width: 100%;

      height: 114px;
      border-radius: 57px;

      margin-top: -6px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.MilestoneHomeCard {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  &__Text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 150%;
    /* identical to box height, or 24px */

    color: rgba(255, 255, 255, 1);
  }

  img {
    width: 48px;

    border: 3px solid #ffffff;
    border-radius: 9.6px;
  }
}

.MilestoneMark {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  &--Amount {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 24px */
    margin-top: 4px;

    /* Class Plus/Cp blue base 900 */

    color: white;
  }

  &--Dot {
    display: block;
    min-width: 14px;
    min-height: 14px;

    border-radius: 50%;

    background-color: #ffffff;

    margin-bottom: 4px;
  }

  &--Award {
    // border: 2px solid #ffffff;
    // background: #F5B100;
    border-radius: 9.6px;
    border : 1px solid #FDCA00;
  }
}