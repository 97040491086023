.Checklist{
    padding: 18px 14px;
    background-color: #FDEEEE;
    &__Image{
        margin: 18px auto 24px auto;
        width: max-content;
    }
    &__header{
        color: #F78C64;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 10px;
    }
    &__subText{
        font-weight: 450;
        font-size: 1.4rem;
        color: #3D2072;
        margin-left: 8px;
    }
    &__textSubHeader{
        display: flex;
        margin-bottom: 20px;
    }
    &__subHeader{
        display: flex;
        flex-direction: column;
        position: relative;
    }
    &__barSeperator{
        position: absolute;
        top: 24%;
        left: 4%;
        height: 24px;
        width: 2px;
        background: #FC7C49;
    }
}