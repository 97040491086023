.circular-chart {
    position: absolute;
    display: block;
    max-width: 80%;
    max-height: 50px;
    transform: rotate(180deg)
  }
  
  .circle {
    // stroke: #4CC790;
    fill: none;
    // stroke-width: 2.8px;
    stroke-linecap: round;
  
  }
  .circle-bg {
    fill: none;
    stroke: #eee;
    // stroke-width: 3.8;
  }
  
.CircularPercentageContainer {
    position: relative;
}

.CircularPercentageText{
    position: absolute;
}