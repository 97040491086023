.PeaknStatsCard {
  min-height: 140px;
  width: 100%;
  position: relative;
  display: grid;
  // background: url("../../../Resources/images/CPL/cardTopBackground.png");
  // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  // background-position: right;
  // background-repeat: no-repeat;
  background-size: 103% 108% !important;
  background-size: 72%;
  // background-color: rgb(43, 20, 81);
  &_leftbgImage {
    position: absolute;
    z-index: 6;
    width: 70%;
  }
  &_duration {
    position: absolute;
    font-size: 1rem;
    top: 66%;
    left: 5%;
    background: linear-gradient(147.14deg, #ff8800 6.95%, #e63535 93.05%);
    border-radius: 10px;
    padding: 3px 8px;
    color: #ffffff;
    z-index: 7;
  }
  &_left {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    max-height: 129px;

    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;

    padding: 12px 0 29px 22px;
    z-index: 7;
  }

  &_right {
    position: relative;
    display: flex;
    justify-content: flex-end;

    img {
      width: 100%;
    }
  }
  &_Text {
   
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    // line-height: 32px;
    // margin-top: 27px;
    // margin-left: 20px;
    // /* White */

    color: white;

    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

