.RevenueCard {
    &__Pointer {
        position: absolute;
        z-index: -1;

        &.left {
            transform: translate(50%, -20px);
        }

        &.right {
            right: 0;
            transform: translate(-100%, -20px);
        }
    }

    &__Top {
        padding: 11px 20px 0px 20px;

        background-repeat: no-repeat;
        background-size: cover;

        &--Label {
           
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 130%;
            font-family: 'Open Sauce One';
             font-style: normal;
            /* or 18px */

            color: #F78C64;

            margin-bottom: 8px;
        }

        &--container {
            display: flex;
        }

        &--leftLabel {
            background: #F78C64;
            border-radius: 2px;
            
            width: 4px;
            height: 48px;
            border-radius: 20px;
            margin-right: 13px;
            margin-top: 0%;
        }
    }

    &__Head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 16px;

        &--Logo {
            display: flex;
            align-items: center;

            &--Text {
                color: #3D2072;
                font-weight: 900;
                font-size: 2.4rem;
                line-height: 100%;
                margin-bottom: 18px;
            }
        }

        &--Points {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 130%;
            /* or 18px */

            text-align: right;

            color: rgba(0, 0, 0, 0.8);

            img {
                margin-right: 4px;
            }
        }

        &--Rank {
            padding: 4px 12px;
            background-color: #00753e41;
            color: #00753e;
            border-radius: 120px;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 150%;
        }
    }

    &__Revenue {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        &--Current {
            font-weight: bold;
            font-size: 2rem;
            line-height: 100%;
        }

        &--Target {
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 100%;
            align-self: flex-end;
        }
    }

    &__New_Footer {
        padding: 22px 0 0;
        &--contianer{
            display: flex;
            justify-content: space-between;
            margin-top: 13px;
        }


        display: flex;
        // grid-template-columns: 1fr 1fr;
        width: 100%;
        // grid-gap: 16px;
        align-items: center;
        justify-content: space-between;

        &--Rank {
            width: 48%;
            display: flex;
            background: transparent;
            background: linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
            backdrop-filter: blur(40px);
            // background: #FE7F08;
            // justify-content: center;
            // border: 1px solid #9278FF;
            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
            border-radius: 16px;
            padding: 12px;
            position: relative;
            border: 2px solid #FDCA00;
            // flex-grow: 1;


            &--heading {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                /* identical to box height, or 16px */

                text-transform: uppercase;
                color: white;
                margin-bottom: 14px;
                white-space: nowrap;
                text-align: center;
                // margin-left: -66px;
            }

            &--imgContainer {
                position: relative;
                &-img{
                    position: absolute;
                    top: 2px;
                    right: -15px;
                    width: 75px;
                }
            }

            &--value {
                display: flex;

                img {
                    margin-right: -3px;
                    // margin-left: -75px;
                }

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    /* identical to box height, or 133% */

                    display: flex;
                    align-items: center;

                    color: white;
                    // margin-left: -6px;
                }
            }
        }

        &--points {
            // flex-grow: 1;
            width: 48%;
            display: flex;
            // background: transparent;
            background: linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
backdrop-filter: blur(40px);
border: 2px solid #FDCA00;
            // background: #FAAF01;
            // justify-content: center;
            
            // border: 1px solid #9278FF;
            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
            border-radius: 16px;
            padding: 12px;
            position: relative;

            &--heading {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                /* identical to box height, or 16px */

                text-transform: uppercase;
                color: white;
                margin-bottom: 14px;
                white-space: nowrap;
                text-align: center;
                // margin-left: -62px;
            }
            &--imgContainer {
                position: relative;
                &-img{
                    position: absolute;
                    top: 3px;
                    right: -24px;
                    width: 70px;
                }
            }
            &--value {
                display: flex;

                img {
                    margin-right: -4px;
                    // margin-left: -62px;
                }

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    /* identical to box height, or 133% */

                    display: flex;
                    align-items: center;

                    color: white;
                    // margin-left: -6px;
                }
            }
        }

        &__left {
            font-weight: bold;
            font-size: 2.8rem;
            line-height: 130%;
            /* identical to box height, or 1.4rem */

            display: flex;
            align-items: center;
            text-align: right;

            color: white;

            &--heading{
                color: #370D3F//#1BB0D6;
            }

            &__mileStoneValue {
                margin-left: 6px;
                color: #F2B029;
            }
        }

        &__right {
            // width: 60%;
            display: flex;
            font-style: normal;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 130%;
            display: flex;
            align-items: center;
            text-align: right;

            color: white;

            justify-content: flex-end;
            align-items: center;

            span {
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                color: rgba(51, 51, 51, 0.7);
            }

            &__points {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                text-align: right;

                color: #333333;
            }
        }
    }
    &__timeLine {
        border-radius: 8px;
        width: 100%;
        padding: 14px 19px;

        &__text{
            font-weight: 700;
            text-align: center;
            font-size: 1.5rem;
        }
        &__ending {
            font-weight: 800;
            text-align: center;
            font-size: 2rem;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            &-text{

            }
            &-time{
                color: #DD295D;
                margin-left:5px;
            }
        }
    }
}

.shine {
    background: #F0DC73;
    background-image: linear-gradient(
        to right,
        #F0DC73 0%,
        #edeef1 20%,
        #F0DC73 40%,
        #F0DC73 100%
      );
      background-repeat: no-repeat;
      background-size: 800px 100%;
      display: inline-block;
      position: relative;
    
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholderShimmer;
      animation-timing-function: linear;
}

@keyframes placeholderShimmer {
    0% {
      background-position: 100% 0;
    }
  
    100% {
      background-position: -100% 0;
    }
  }