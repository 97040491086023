.ToolsSection {
  padding: 21px 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px 16px;
}

// .ToolCard {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   margin-bottom: 8px;

//   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
//   border-radius: 4px;

//   &:last-child {
//     margin-bottom: 0;
//   }

//   height: 100px;

//   &__Text {
//     padding: 0 0 0 20px;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 1.6rem;
//     line-height: 24px;
//     /* or 150% */

//     display: flex;
//     align-items: center;

//     color: #ffffff;
//   }
// }
