.progressBarContainer {
  margin-top: 50px;
  margin-bottom: 20px;

  &__ProgressBar {
    position: relative;
    height: 20px;
    width: 100%;
    box-shadow: inset 0px 2px 4px RGB(0 0 0 / 16%);
    border-radius: 10px;
    background-color: #F2F2F2;
    border: 1px solid #E5E5E5;

    // border: 0.2px solid #e5e5e5;
    // border: 0.2px solid #318f43;
    border-radius: 12px;

    display: flex;
    align-items: center;



    span {
      display: block;
      height: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &__Indicator {
      border-radius: 12px;
      overflow: hidden;

      &--Fill {
        background: #F78C64;
        border: 1px solid #F2B029;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        -webkit-animation-name: progressBar;
        animation-name: progressBar;

        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: 1.5s;
        -webkit-animation-delay: 1.5s;
      }
    }
  }

  &__progressBarDividersContainer {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;

    &__completion {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */


      /* Green */

      color: #08BD80;
    }

    &__goal {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      text-align: right;

      /* Orange */

      color: #FFAD3B;
    }

    &__row {
      width: 88%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 286px;

      .progressBarDividers {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__priceEmblem {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          width: 29px;
          height: 18px;
          margin: 8px 0;
          font-size: 12px;
          color: #ffffff;
          z-index: 1;

          &--top {
            &::after {
              content: "";
              height: 14px;
              width: 14px;
              background: var(--databgColor);
              position: absolute;
              transform: rotate(45deg);
              top: -4px;
              z-index: -1;
            }
          }

          &--bottom {
            &::after {
              content: "";
              height: 14px;
              width: 14px;
              background: var(--databgColor);
              position: absolute;
              transform: rotate(45deg);
              bottom: 14px;
              z-index: -1;
            }
          }
        }

        &__progressBarDividerLines {
          width: 2px;
          background: #fff;
          height: 11px;
          margin-top: 1px;
        }
      }
    }
  }
  &__qualifierIcon{
    position: absolute;
    right: 5px;
    bottom: 23px;
  }

  &__qualifierBar{
    position: absolute;
    right: 18px;
    border: 2px solid #CFCFCF;
    height: 13px;
  }
}

@-webkit-keyframes progressBar {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 100%;
  }
}

@keyframes progressBar {
  0% {
    max-width: 0;
  }

  100% {
    max-width: 100%;
  }
}