.Tools {
  padding: 24px 24px 60px;
  // background: url("../../Resources/images/IPL/detailsBackground.png") no-repeat;
  background-color: #fdeeed;

  // background: radial-gradient(50% 50% at 50% 50%, #F4BB40 0%, #FAE19D 53.12%, #F5BE41 100%);

  &__Top {
    margin-bottom: 24px;

    &--Heading {
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 150%;
      /* identical to box height, or 33px */

      display: flex;
      align-items: center;

      color: #f78c64;
    }

    &--SubHeading {
      margin-top: 8px;
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 130%;

      color: #3d2072;
    }
  }

  &__Body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: 16px;
  }
}

.ToolCard {
  background: #1480d1;
  border-radius: 8px !important;
  position: relative;
  background-size: 100% 100% !important;
  background-position: top left !important;
  // border: 1px solid #FDCA00;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: auto;

  &--image {
    position: absolute;
    top: 18px;
    right: -18px;
  }

  &--Icon {
    width: 24px;
    height: 24px;

    margin-bottom: 13px;

    flex: 0 0 auto;
    flex-basis: auto;
    margin: 0;
  }

  &__Label {
    font-style: normal;
    font-weight: 700;
    font-size: 2vh;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: white;
    mix-blend-mode: normal;
    flex: 1 1;
  }

  &--Text {
    font-style: normal;
    font-weight: 450;
    font-size: 1.4rem;
    line-height: 130%;
    color: white;
    margin-bottom: 32px;
    width: 65%;
  }

  &--Arrow {
    width: 18px;
  }
}
