.slick-dots li button:before {
  color: #EFEFEF !important;
  opacity: 1 !important;
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

.slick-dots li.slick-active button:before {
  color: #8D07F5 !important;
}

.Home {
  padding: 16px;
  width: 100vw;
  // background: url("../../../Resources/images/IPL/slideBackground.png") no-repeat;
  // background-color: #09084c;
  background-size: 100% 100% !important;
  // background: linear-gradient(150.06deg, #FDEDD3 -3.07%, #FFE5B5 134.43%);
  // background-image: url("../../../Resources/images/baisakhi/SlidesBackGroundImg.svg");

  &__SliderButton {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      z-index: 1000;

      // box-shadow: 0px 1.33333px 2.66667px rgba(172, 173, 175, 0.14),
      //   0px 2px 2.66667px rgba(172, 173, 175, 0.12),
      //   0px 0.666667px 3.33333px rgba(172, 173, 175, 0.2);
      // border-radius: 4px;
      // background: #FFFFFF;
      background: linear-gradient(110.03deg, #8800F3 7.45%, #A737FF 87.18%);
      // background: linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
      box-shadow: 0px 1.33333px 2.66667px rgba(172, 173, 175, 0.14), 0px 2px 2.66667px rgba(172, 173, 175, 0.12), 0px 0.666667px 3.33333px rgba(172, 173, 175, 0.2);
      border-radius: 50px;
      // border: 1px solid #F2B029;
      border: 1px solid  #FFE3A6;
      // Linear Gradient
      // #FFE3A6
      // 75%
      // #FEFAEF
      // 74%
      // #FFFFFF
      // 0%
      // Linear Gradient
      // #FFFFFF
      // 40%
      // #FFFFFF
      // 0%
      // #FFFFFF
      // 0%
      // Radial Gradient
      // #FFFFFF
      // 70%
      // #54881C
      // 74%
      // #FFFFFF
      // 0% ;
      padding: 10px 14px;

      &:active {
        box-shadow: none;
      }
    }

    &--Prev {
      background: #FFFFFF;
    }

    &--Next {
      transform: rotate(180deg);
      background: #FFFFFF;
    }
  }
}

.CardFooter {
  padding: 20px;
  bottom: 0;
  width: 100%;

  &__Text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 130%;
    /* or 18px */

    color: black;

    display: flex;
    justify-content: center;

    text-align: center;

    margin-bottom: 20px;
  }

  &__Button {
    width: 98%;

    // background: linear-gradient(90deg, #019FD1 0%, #7AE0EE 108.74%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    border-radius: 24px;
    height: 48px;
    margin: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Slide {
  // border: 1px solid #9278FF;
  height: 480px;
  position: relative;

  overflow: hidden;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  // border: 2px solid;
  border-radius: 16px;
  
   


  // background: #FFFFFF;
  // border: 1px solid #F2B029;
  &__CheckList {
   
    padding: 16px 0px 0px 0px;
    width: max-content;
    position: relative;
    margin: -18px auto;
    &__org{
      position: absolute;
      top: 9%;
      left: 0%;
      // display: flex;
      align-items: center;
    }
    &__orgName{
      font-size: 1.8rem;
      color: rgb(61, 32, 114);
      margin: 18px 29px 0px 29px;
      text-align: center;
      width: 28rem;
      // margin-left: 10px;
    }
    &__orgLogo{
      width: 7rem;
      margin: auto;
    }
  }

  &__Rewards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid #FDCA00;
    padding: 20px 20px 9px 20px;
 
    img {
      // margin-bottom: 20px;
      width: 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &__Text {
      &--Impact {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;

        color: rgba(0, 0, 0, 0.87);

        margin-bottom: 8px;
      }

      &--Count {
        font-style: normal;
        font-weight: 900;
        font-size: 2.2rem;
        line-height: 150%;
        /* identical to box height, or 36px */

        text-align: center;

        color: #299e6e;
      }
    }
  }

  &__Landing {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      display: flex;
      align-items: flex-end;

      position: absolute;
      bottom: 0;
      left: -10%;
      width: 120%;
      // height: 314px;

      z-index: 2;
    }

    &--Text {
      padding: 14px 20%;

      background-color: #c8c8ff;

      position: absolute;
      bottom: 20%;

      z-index: 1;

      span {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 150%;
        /* or 21px */

        text-align: center;

        color: rgba(0, 0, 0, 0.87);

        z-index: 3;
      }
    }
  }

  &__Image {
    padding: 20px;

    img {
      max-height: 194px;
      width: 100%;

      background: #ebe3d9;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      text-align: center;

      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.FreedomSlide{

  &__Section{
    padding: 24px;

    &__Revenue{
      border-left: 2px solid #F78C64;
      padding-left: 12px;
      margin-bottom: 12px;
      margin-top: 5%;

      &--Label{
        font-style: normal;
font-weight: 800;
font-size: 12px;
line-height: 150%;
/* identical to box height, or 18px */


color: #F78C64;
      }

      &--Value{
        font-style: normal;
font-weight: 800;
font-size: 24px;
line-height: 100%;
/* identical to box height, or 24px */


color:#3D2072;
      }
    }

    &__Goal{
      margin-top: 8px;
      margin-bottom: 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &--Completed{
        font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 130%;
/* or 18px */


color:#3D2072;
      }
      &--Target{
        font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 130%;
/* or 18px */


color: #3D2072;
      }
    }

    &--Divider{
      width: 100%;
      height: 1px;

      // background-image: linear-gradient(to right, #4D322F, transparent);
    }

    &__Promo{
      display: flex;
      justify-content: center;
      
      img{
        width: 83%;
        margin-top: -30px;
        // border: 1px solid #FDCA00;
        
       

      }
    }
  }
}